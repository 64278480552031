import { Routes, Route } from "react-router-dom";

import Home from "./routes/home/home";
import Navigation from "./routes/navigation/navigation";
import ContactUs from "./routes/contact-us/contact-us";
import Testimonials from "./routes/testimonials/testimonials";
import Booking from "./routes/booking/booking";
import Halls from "./routes/halls/halls";
import Menus from "./routes/menus/menus";
import Packages from "./routes/packages/packages";
import Gallery from "./routes/gallery/gallery";
import Terms from "./routes/terms/terms";
import Review from "./routes/review/review";
import Footer from "./components/footer/footer.component";

import "bulma/css/bulma.min.css";
import "./App.css";
import LandingPage from "./routes/landing/landing";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="testimonials" element={<Testimonials />} />
          <Route path="booking" element={<Booking />} />
          <Route path="halls" element={<Halls />} />
          <Route path="menus" element={<Menus />} />
          <Route path="packages" element={<Packages />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="terms" element={<Terms />} />
          <Route path="review" element={<Review />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
