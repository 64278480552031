import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";

const Menus = () => {

  const styles = {
    wrapper: {
      backgroundColor: "#f0f0f0", // Change the color value as needed
      minHeight: "100vh", // To cover the whole viewport height
    },
    hero: {
      // backgroundColor: "#f0f0f0",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
  };


  return (
    <div style={styles.wrapper}>

      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! - How can we help?"
        avatar={logo}
      />
      <section className="hero mb-6" style={styles.hero}>
        <div className="container has-text-centered">
          <h1
            className="title is-3 is-size-4-mobile main-title"
            style={styles.mainTitle}
          >
            Our Menu
          </h1>
        </div>
      </section>
      <div className="container p-4">
        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image">
                  {/* <img src={HallOneImage} alt="Placeholder image" /> */}
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">Menu One</p>
                  </div>
                </div>
                <div className="subtitle is-5">
                  <strong>Rs. 750 per head</strong>
                  <hr className="content-divider" />
                </div>

                <div className="subtitle is-5">
                  <h1 className="title is-5 is-size-5-mobile tag">
                    Main Course
                  </h1>
                  <br />
                  <p> • Chicken Korma</p>
                  <br />
                  <p> • Roghni Naan</p>
                  <br />
                  <p>
                    • Rice Dish <strong>(Choose One Dish)</strong>
                  </p>
                  <p> - Chicken Pulao</p>
                  <p> - Chicken Biryani</p>
                  <p> - Vegetable Pulao</p>

                  <hr className="content-divider" />
                  <h1 className="title is-5 is-size-5-mobile tag">Sides</h1>

                  <br />
                  <p>
                    • Salads <strong>(Choose One Group)</strong>
                    <p>- Fresh, Russian, and Macaroni</p>
                    <p>- Fresh, Russian, and Lobia/Chana</p>
                  </p>
                  <hr className="content-divider" />
                  <h1 className="title is-5 is-size-5-mobile tag">Deserts</h1>

                  <br />
                  <p>
                    • Sweet Dish <strong>(Choose One Dish)</strong>
                  </p>
                  <p>- Kheer</p>
                  <p>- Custard</p>
                  <p>- Zarda</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image">
                  {/* <img src={HallOneImage} alt="Placeholder image" /> */}
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">Menu Two</p>
                  </div>
                </div>
                <div className="subtitle is-5">
                  <strong>Rs. 790 per head</strong>
                  <hr className="content-divider" />
                </div>

                <div className="subtitle is-5">
                  <p>
                    <h1 className="title is-5 is-size-5-mobile tag">
                      Main Course
                    </h1>
                  </p>
                  <br />
                  <p> • Beef Korma</p>
                  <br />
                  <p> • Roghni Naan</p>
                  <br />
                  <p>
                    • Rice Dish <strong>(Choose One Dish)</strong>
                  </p>
                  <p> - Chicken Pulao</p>
                  <p> - Chicken Biryani</p>
                  <p> - Vegetable Pulao</p>

                  <hr className="content-divider" />
                  <h1 className="title is-5 is-size-5-mobile tag">Sides</h1>
                  <br />
                  <p>
                    • Salads <strong>(Choose One Group)</strong>
                    <p>- Fresh, Russian, and Macaroni</p>
                    <p>- Fresh, Russian, and Lobia/Chana</p>
                  </p>
                  <hr className="content-divider" />
                  <h1 className="title is-5 is-size-5-mobile tag">Deserts</h1>
                  <br />
                  <p>
                    • Sweet Dish <strong>(Choose One Dish)</strong>
                  </p>
                  <p>- Kheer</p>
                  <p>- Custard</p>
                  <p>- Zarda</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image">
                  {/* <img src={HallOneImage} alt="Placeholder image" /> */}
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">Menu Three</p>
                  </div>
                </div>
                <div className="subtitle is-5">
                  <strong>Rs. 990 per head</strong>
                  <hr className="content-divider" />
                </div>

                <div className="subtitle is-5">
                  <h1 className="title is-5 is-size-5-mobile tag">
                    Main Course
                  </h1>
                  <br />
                  <p> • Mutton Korma</p>
                  <br />
                  <p> • Roghni Naan</p>
                  <br />
                  <p>
                    • Rice Dish <strong>(Choose One Dish)</strong>
                  </p>
                  <p> - Chicken Pulao</p>
                  <p> - Chicken Biryani</p>
                  <p> - Vegetable Pulao</p>

                  <hr className="content-divider" />
                  <h1 className="title is-5 is-size-5-mobile tag">Sides</h1>
                  <br />
                  <p>
                    • Salads <strong>(Choose One Group)</strong>
                    <p>- Fresh, Russian, and Macaroni</p>
                    <p>- Fresh, Russian, and Lobia/Chana</p>
                  </p>
                  <hr className="content-divider" />
                  <h1 className="title is-5 is-size-5-mobile tag">Deserts</h1>
                  <br />
                  <p>
                    • Sweet Dish <strong>(Choose One Dish)</strong>
                  </p>
                  <p>- Kheer</p>
                  <p>- Custard</p>
                  <p>- Zarda</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <div className="card-image">
                <figure className="image">
                  {/* <img src={HallOneImage} alt="Placeholder image" /> */}
                </figure>
              </div>
              <div className="card-content">
                <div className="media">
                  <div className="media-content">
                    <p className="title is-4">Menu Add-Ons</p>
                  </div>
                </div>
                <div className="subtitle is-5">
                  {/* <strong>Rs. 790 per head</strong> */}
                  <hr className="content-divider" />
                </div>

                <div className="subtitle is-5">
                  <div className="columns">
                    <div className="column">
                      <h1 className="title is-5 is-size-5-mobile tag">Kebab</h1>
                      <br />
                      <p>
                        • Gola Kabab - <strong>Rs. 250 per head</strong>
                      </p>
                      <p>
                        • Seekh Kabab - <strong>Rs. 250 per head</strong>
                      </p>
                      <p>
                        • Reshmi Kabab - <strong>Rs. 325 per head</strong>
                      </p>
                      <br />
                      <h1 className="title is-5 is-size-5-mobile tag">
                        Chicken
                      </h1>
                      <br />

                      <p>
                        • Chicken Steam Roast -{" "}
                        <strong>Rs. 250 per head</strong>
                      </p>
                      <p>
                        • Chicken Boti - <strong>Rs. 250 per head</strong>
                      </p>
                      <p>
                        • Chicken Pakora - <strong>Rs. 250 per head</strong>
                      </p>
                      <br />
                      <h1 className="title is-5 is-size-5-mobile tag">Soup</h1>
                      <br />
                      <p>
                        • Chicken Corn Soup - <strong>Rs. 120 per head</strong>
                      </p>
                      <p>
                        • Hot and Sour Soup - <strong>Rs. 120 per head</strong>
                      </p>
                    </div>
                    <div className="column">
                      <h1 className="title is-5 is-size-5-mobile tag">Sweet</h1>
                      <br />
                      <p>
                        • Gajar Halwa - <strong>Rs. 130 per head</strong>
                      </p>
                      <p>
                        • Gulab Jamun - <strong>Rs. 150 per head</strong>
                      </p>
                      <br />
                      <h1 className="title is-5 is-size-5-mobile tag">Tea</h1>
                      <br />
                      <p>
                        • Kashmiri Chai / Pink Tea -{" "}
                        <strong>Rs. 120 per head</strong>
                      </p>
                      <p>
                        • Green tea - <strong>Rs. 50 per head</strong>
                      </p>
                      <br />
                      <h1 className="title is-5 is-size-5-mobile tag">
                        Drinks
                      </h1>
                      <br />

                      <p>
                        • Cold Drinks and Water -{" "}
                        <strong>Rs. 90 per head</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menus;
