import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";

import MBH from "../../components/mbh/mbh.component";
import Halls from "../halls/halls";
import Menus from "../menus/menus";
import Packages from "../packages/packages";
import Gallery from "../gallery/gallery";
import Terms from "../terms/terms";
import ContactUs from "../contact-us/contact-us";
import Booking from "../booking/booking";
import Footer from "../../components/footer/footer.component";

import { Element } from 'react-scroll';

const Home = () => {
  return (
    <>
      <Element name="home"><MBH /></Element>
      <Element name="halls"><Halls /></Element>
      <Element name="menus"><Menus /></Element>
      <Element name="pakages"><Packages /></Element>
      <Element name="gallery"><Gallery /></Element>
      <Element name="terms"><Terms /></Element>
      <Element name="contact"><ContactUs /></Element>
      <Element name="booking"><Booking /></Element>
      <Footer />

      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! — How can we help?"
        avatar={logo}
        message="Test"
      />
    </>
  );
};

export default Home;
