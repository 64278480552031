import { useEffect, useState } from "react";

const Testimonials = () => {
  // const [reviews, setReviews] = useState([]);
  // useEffect(() => {
  //   fetch(
  //     "https://404302.xyz/https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJaYeZoffr3zgRuSR5rkUikEA&key=AIzaSyDj03Z-lkJH7XLy6UPLFlkap_PJMnaxtBU"
  //   )
  //     .then((response) => response.json())
  //     .then((google) => {
  //       console.log(google);
  //       // setReviews(pokemons);
  //     });
  // }, []);

  // AIzaSyAr2xO0MdCOGKOXS19iXsglN1aJoacr_K8;

  return (
    <div className="container">
      <div key={""} className="column is-multiline is-12-mobile is-6-tablet is-3-desktop">
        <div className="card">
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image" />
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4">John Smith</p>
                <p class="subtitle is-6">@johnsmith</p>
              </div>
            </div>

            <div class="content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec iaculis mauris. <a>@bulmaio</a>.
              <a href="#">#css</a> <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
