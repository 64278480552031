import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";

const Terms = () => {

  const styles = {
    hero: {
      backgroundColor: "#f0f0f0",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
    hr: {
      border: "1px solid #383838",
      borderRadius: "5px",
      maxWidth: "50%",
      margin: "0 auto",
    },
    subtitle: {
      fontWeight: "300",
      lineHeight: "1.6",
      color: "#383838",
      backgroundColor: "rgba(240, 240, 240, 0.9)",
      borderRadius: "10px",
      padding: "20px",
    },
  };
  return (
    <>
      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! — How can we help?"
        avatar={logo}
      />
      <section className="hero mb-6">
        <div className="">

          <div className="container has-text-centered">
            <h1
              className="title is-3 is-size-4-mobile main-title"
              style={styles.mainTitle}
            >
              Terms and Conditions
            </h1>
          </div>


          {/* <div className="container has-text-centered">
            <h1 className="title is-3 is-size-4-mobile main-title">TERMS AND CONDITIONS</h1>
            <hr class="rounded" />
            <div className="columns is-centered is-vcentered">
              <div className="column is-8 pl-6 pr-6"></div>
            </div>
          </div> */}




        </div>
      </section>
      <div className="container p-4">
        <div className="columns is-centered">
          <div className="column is-8">
            <div className="card">
              <div className="card-content">
                <div className="subtitle is-5">
                  <p>
                    1. The Musa Banquet Halls impose a maximum rental time of 4 hours for any function. Additional time
                    may be granted at an hourly rate of Rs. 3000 per hour, provided that the hall is available.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    2. Accommodation will only be provided for the number of guests that were booked. Any additional
                    guests will be subject to an additional per-head fee.
                  </p>
                  <hr className="content-divider" />
                  <p>3. The use of air conditioning will be charged at a rate of Rs. 3000 per hour per hall.</p>
                  <hr className="content-divider" />
                  <p>
                    4. A deposit of 50% must be made at the time of booking. The remaining balance must be paid 3 days
                    prior to the event. Failure to pay the remaining balance will result in the function being
                    considered postponed.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    5. In the event of cancellation, the deposit will not be refunded. However, the deposit may be
                    applied to a future date, provided that we receive notice at least 48 hours in advance.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    6. A one-dish menu is required for all functions, even if outside food is being brought in. Any
                    violation of this rule is the sole responsibility of the customer, and the hall management will not
                    be held liable for any fines or legal repercussions that may result.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    7. The use of fireworks and aerial firing is strictly prohibited under the laws of the Government of
                    Pakistan. Customers are discouraged from engaging in any immoral or inappropriate activities.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    8. The customer is responsible for ensuring that their guests comply with the laws and regulations
                    of the Federal and Provincial Governments. The customer will be solely responsible for any
                    violations or damages resulting from the actions of their guests.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    9. Customers are responsible for taking care of their own belongings and vehicles. The hall
                    management will not be responsible for any loss or damage.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    10. All functions must end by 10 p.m. in accordance with government regulations. No extensions will
                    be permitted.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    11. In accordance with government regulations, a 5% Withholding Tax and 5% Sales Tax will be charged
                    to the customer separately.
                  </p>
                  <hr className="content-divider" />
                  <p>
                    12. Music and bands are prohibited on the premises after 10 p.m. The customer cannot object to this
                    policy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6" />
      </div>
    </>
  );
};

export default Terms;
