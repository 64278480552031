import HallOne from "../../assets/Photos/HallOne/HallOne1.jpeg";
import HallTwo from "../../assets/Photos/HallTwo/HallTwo1.jpg";
import HallThree from "../../assets/Photos/HallThree/HallThree1.jpg";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";
import ModalImage from 'react-modal-image';

const Halls = () => {
  const styles = {
    hero: {
      // backgroundColor: "#f0f0f0",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
    hr: {
      border: "1px solid #383838",
      borderRadius: "5px",
      maxWidth: "50%",
      margin: "0 auto",
    },
    card: {
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      borderRadius: "10px",
      overflow: "hidden",
      marginBottom: "2rem",
    },
    cardContent: {
      padding: "1.5rem",
    },
    title: {
      marginBottom: "1rem",
    },
  };

  return (
    <div>
      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! — How can we help?"
        avatar={logo}
      />
      <section className="hero mb-6" style={styles.hero}>
        <div className="container has-text-centered">
          <h1
            className="title is-3 is-size-4-mobile main-title"
            style={styles.mainTitle}
          >
            Our Halls
          </h1>
        </div>
      </section>



      <div className="container p-4">
        <div className="columns is-multiline">
          {[HallOne, HallTwo, HallThree].map((hall, index) => (
            <div className="column is-one-third" key={index}>
              <div className="card" style={styles.card}>
                <div className="card-image">
                  <figure className="image">
                    <ModalImage
                      small={hall}
                      large={hall}
                      alt="Hall"
                      className="thumbnail"
                    />
                  </figure>
                </div>
                <div className="card-content" style={styles.cardContent}>
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4" style={styles.title}>Hall {index + 1}</p>
                    </div>
                  </div>
                  <div className="subtitle is-5">
                    <strong>Rs. {index === 0 ? 30 : 35},000</strong>
                  </div>
                  <div className="subtitle is-5">
                    <p>Capacity: {150 + (index * 20)}</p>
                    <p>Location: {["Ground", "First", "Second"][index]} Floor</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default Halls;
