import React from 'react';
import './footer.styles.css';

import musalogo_inverted from "../../assets/musalogo_invert.svg"



const Footer = () => {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <img
              src={musalogo_inverted}
              className="img-responsive"
              alt="Musa Banquet Halls logo"
              width="200px"

            />
            <h3>Musa Banquet Halls</h3>
            <p class="slogan">
              Crafting Timeless Memories
            </p>
          </div>
          <div className="col">
            <h2>Quick Links</h2>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/halls">
                  Our Halls
                </a>
              </li>
              <li>
                <a href="/menus">
                  Our Menus
                </a>
              </li>
              <li>
                <a href="/pakages">
                  Our Packages
                </a>
              </li>
              <li>
                <a href="/gallery">Gallery</a>
              </li>
              <li>
                <a href="/terms">
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              <li>
                <a href="/booking">
                  Request a Booking
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <h2>Contact Us</h2>
            <ul>
              <li>
                Islamabad Express Highway
                <br />
                Opposite Zia Masjid
                <br />
                Rawalpindi, Punjab 46000
              </li>
              <li>
                Phone Number: <a href="tel:0333 5749040">0333 5749040</a>
              </li>
              <li>
                Email: <a href="mailto:musabanquethalls@gmail.com">musabanquethalls@gmail.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;