import { Link } from "react-router-dom";
import "./booking.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";
import { useState } from "react";

const Booking = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedHallOption, setSelectedHallOption] = useState("");
  const [selectedColorOption, setSelectedColorOption] = useState("");
  const [selectedStageOption, setSelectedStageOption] = useState("");
  const [selectedTemperatureOption, setSelectedTemperatureOption] = useState("");
  const [selectedMenuOption, setSelectedMenuOption] = useState("");
  const [checkedSeekhKababGolaKabab, setCheckedSeekhKababGolaKabab] = useState(false);
  const [checkedReshmiKabab, setCheckedReshmiKabab] = useState(false);
  const [checkedChickenBotiPakora, setCheckedChickenBotiPakora] = useState(false);
  const [checkedKashmiriChaiPinkTea, setCheckedKashmiriChaiPinkTea] = useState(false);
  const [checkedGreenTea, setCheckedGreenTea] = useState(false);
  const [checkedGajarHalwa, setCheckedGajarHalwa] = useState(false);
  const [checkedGulabJamun, setCheckedGulabJamun] = useState(false);
  const [checkedDrinksWater, setCheckedDrinksWater] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [eventTime, setEventTime] = useState("");
  const [selectedEventTime, setSelectedEventTime] = useState("");
  const [selectedExpectedGuests, setSelectedExpectedGuests] = useState("");
  const [anythingElse, setAnythingElse] = useState("");
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedSoup, setCheckedSoup] = useState(false);
  const [selectedRiceDishOption, setSelectedRiceDishOption] = useState("");
  const [selectedSaladOption, setSelectedSaladOption] = useState("");
  const [selectedDessertOption, setSelectedDessertOption] = useState("");
  const [checkedGolaKabab, setCheckedGolaKabab] = useState(false);
  const [checkedSeekhKabab, setCheckedSeekhKabab] = useState(false);
  const [checkedChickenSteamRoast, setCheckedChickenSteamRoast] = useState(false);
  const [checkedChickenBoti, setCheckedChickenBoti] = useState(false);
  const [checkedChickenPakora, setCheckedChickenPakora] = useState(false);
  const [checkedChickenCornSoup, setCheckedChickenCornSoup] = useState(false);
  const [checkedHotAndSourSoup, setCheckedHotAndSourSoup] = useState(false);
  const [checkedColdDrinksAndWater, setCheckedColdDrinksAndWater] = useState(false);
  const [expectedGuests, setExpectedGuests] = useState("");
  const [typeOfEvent, setTypeOfEvent] = useState("");

  const styles = {

    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    }
  }

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Booking Request has been submitted!`);
    window.scrollTo(0, 0);
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPhoneNumber("");
    setSelectedHallOption("");
    setSelectedColorOption("");
    setSelectedStageOption("");
    setSelectedTemperatureOption("");
    setSelectedMenuOption("");
    setCheckedSeekhKababGolaKabab(false);
    setCheckedReshmiKabab(false);
    setCheckedChickenBotiPakora(false);
    setCheckedKashmiriChaiPinkTea(false);
    setCheckedGreenTea(false);
    setCheckedGajarHalwa(false);
    setCheckedGulabJamun(false);
    setCheckedDrinksWater(false);
    setEventDate(new Date());
    setEventTime("");
    setSelectedEventTime("");
    setSelectedExpectedGuests("");
    setAnythingElse("");
    setCheckedTerms(false);
    setCheckedSoup(false);
    setSelectedRiceDishOption("");
    setSelectedSaladOption("");
    setSelectedDessertOption("");
    setCheckedGolaKabab(false);
    setCheckedSeekhKabab(false);
    setCheckedChickenSteamRoast(false);
    setCheckedChickenBoti(false);
    setCheckedChickenPakora(false);
    setCheckedChickenCornSoup(false);
    setCheckedHotAndSourSoup(false);
    setCheckedColdDrinksAndWater(false);
    setExpectedGuests("");
    setTypeOfEvent("");

    // Construct the data object to be sent in the request
    const data = {
      lastName,
      firstName,
      emailAddress,
      phoneNumber,
      selectedHallOption,
      selectedColorOption,
      selectedStageOption,
      selectedTemperatureOption,
      selectedMenuOption,
      checkedSeekhKababGolaKabab,
      checkedReshmiKabab,
      checkedChickenBotiPakora,
      checkedKashmiriChaiPinkTea,
      checkedGreenTea,
      checkedGajarHalwa,
      checkedGulabJamun,
      checkedDrinksWater,
      eventDate,
      eventTime,
      selectedEventTime,
      selectedExpectedGuests,
      anythingElse,
      checkedTerms,
      checkedSoup,
      selectedRiceDishOption,
      selectedSaladOption,
      selectedDessertOption,
      checkedGolaKabab,
      checkedSeekhKabab,
      checkedChickenSteamRoast,
      checkedChickenBoti,
      checkedChickenPakora,
      checkedChickenCornSoup,
      checkedHotAndSourSoup,
      checkedColdDrinksAndWater,
      expectedGuests,
      typeOfEvent,
    };

    console.log(data);

    // Send the POST request to xyz.com
    fetch("https://404302.xyz/https://hooks.zapier.com/hooks/catch/12148060/b7du4ao/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // Do something with the response here
      })
      .catch((error) => {
        // Handle any errors here
      });
  };

  return (
    <>
      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! — How can we help?"
        avatar={logo}
      />


      <section className="hero mb-6" style={styles.hero}>
        <div className="container has-text-centered">
          <h1
            className="title is-3 is-size-4-mobile main-title"
            style={styles.mainTitle}
          >
            Request a Booking
          </h1>

          <section className="section">
            <form onSubmit={handleSubmit}>
              <div className="columns">

                <div className="column has-text-left">
                  {/* ************************************* */}

                  <div className="button-background mb-5 tag title is-medium" type="submit">
                    ABOUT YOU
                  </div>



                  <div class="field-body">
                    <div class="field">
                      <label className="label title is-5">First Name</label>
                      <p class="control is-expanded has-icons-left">
                        <input
                          class="input"
                          type="text"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(event) => setFirstName(event.target.value)}
                          required
                        />
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                      </p>
                    </div>

                    <div class="field">
                      <label className="label title is-5">Last Name</label>
                      <p class="control is-expanded has-icons-left">
                        <input
                          class="input"
                          type="text"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(event) => setLastName(event.target.value)}
                          required
                        />
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                      </p>
                    </div>
                  </div>

                  {/* ************************************* */}
                  <div class="field-body mt-5">
                    {/* ----------------------- */}

                    <div class="field">
                      <label className="label title is-5">Email Address</label>
                      <p class="control is-expanded has-icons-left">
                        <input
                          class="input"
                          type="text"
                          placeholder="Email Address"
                          value={emailAddress}
                          onChange={(event) => setEmailAddress(event.target.value)}
                          required
                        />
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                      </p>
                    </div>
                    {/* ----------------------- */}
                    <div class="field">
                      <label className="label title is-5">Phone Number</label>
                      <p class="control is-expanded has-icons-left">
                        <input
                          class="input"
                          type="text"
                          placeholder="Phone Number"
                          value={phoneNumber}
                          onChange={(event) => setPhoneNumber(event.target.value)}
                          required
                        />{" "}
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                      </p>
                    </div>
                  </div>
                  <hr className="content-divider" />
                  <div className="button-background mb-5 tag title is-medium" type="submit">
                    YOUR HALL
                  </div>
                  {/* ************************************* */}
                  <div class="field-body">
                    <div class="field">
                      <label className="label title is-5">Preferred Hall</label>
                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedHallOption}
                            onChange={(event) => setSelectedHallOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select a Hall
                            </option>

                            <option>Hall One</option>
                            <option>Hall Two</option>
                            <option>Hall Three</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/halls" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div>

                    {/* <div class="field">
                      <label className="label title is-5"> Chair Color</label>
                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedColorOption}
                            onChange={(event) => setSelectedColorOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select the Chair Color
                            </option>
                            <option>Option #1</option>
                            <option>Option #2</option>
                            <option>Option #2</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/halls" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div> */}
                  </div>
                  {/* ************************************* */}

                  {/* <div class="field-body mt-5">
                    <div class="field">
                      <label className="label title is-5">Stage</label>

                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedStageOption}
                            onChange={(event) => setSelectedStageOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select a Stage
                            </option>
                            <option>Option #1</option>
                            <option>Option #2</option>
                            <option>Option #2</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/halls" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div class="field">
                      <label className="label title is-5">Temperature</label>

                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedTemperatureOption}
                            onChange={(event) => setSelectedTemperatureOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select a Temperature Package
                            </option>
                            <option>Heating</option>
                            <option>Cooling</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/packages" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <hr className="content-divider" />

                  <div className="button-background mb-5 tag title is-medium" type="submit">
                    YOUR MENU
                  </div>

                  <div class="field">
                    <label className="label title is-5">Preferred Menu</label>

                    <div class="control is-expanded">
                      <div class="select is-fullwidth">
                        <select
                          value={selectedMenuOption}
                          onChange={(event) => setSelectedMenuOption(event.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select a Menu
                          </option>
                          <option>Menu One (Chicken Korma & Roghni Naan)</option>
                          <option>Menu Two (Beef Korma & Roghni Naan)</option>
                          <option>Menu Three (Mutton Korma & Roghni Naan)</option>
                        </select>
                      </div>
                      <p class="help subtitle is-6">
                        <Link to="/menus" target="_blank" rel="noopener noreferrer">
                          Learn more.
                        </Link>
                      </p>
                    </div>
                  </div>

                  {/* ************************************* */}

                  <div class="field-body mt-5">
                    <div class="field">
                      <label className="label title is-5">Rice Dish</label>

                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedRiceDishOption}
                            onChange={(event) => setSelectedRiceDishOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select a Rice Dish
                            </option>
                            <option>Chicken Pulao</option>
                            <option>Chicken Biryani</option>
                            <option>Vegetable Pulao</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/menus" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div>

                    <div class="field">
                      <label className="label title is-5">Salad</label>

                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedSaladOption}
                            onChange={(event) => setSelectedSaladOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select a Salad
                            </option>
                            <option>Fresh, Russian, and Macaroni</option>
                            <option>Fresh, Russian, and Lobia/Chana</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/menus" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div class="field">
                      <label className="label title is-5">Desert</label>

                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedDessertOption}
                            onChange={(event) => setSelectedDessertOption(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select a Desert
                            </option>
                            <option>Kheer</option>
                            <option>Custard</option>
                            <option>Zarda</option>
                          </select>
                        </div>
                        <p class="help subtitle is-6">
                          <Link to="/menus" target="_blank" rel="noopener noreferrer">
                            Learn more.
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>

                  <hr className="content-divider" />

                  <label className="label title is-5 mt-5 mb-5">Your Add-On Menu Item Options</label>

                  <div class="field">
                    <div class="control">
                      {/* ********** */}
                      <div className="columns">
                        <div className="column">
                          <h1 className="title is-5 is-size-5-mobile tag">Kebab</h1>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedGolaKabab}
                              onChange={(event) => setCheckedGolaKabab(event.target.checked)}
                            />{" "}
                            Gola Kabab
                          </label>
                          <br />{" "}
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedSeekhKabab}
                              onChange={(event) => setCheckedSeekhKabab(event.target.checked)}
                            />{" "}
                            Seekh Kabab
                          </label>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedReshmiKabab}
                              onChange={(event) => setCheckedReshmiKabab(event.target.checked)}
                            />{" "}
                            Reshmi Kabab
                          </label>
                        </div>
                        <div className="column">
                          <h1 className="title is-5 is-size-5-mobile tag">Chicken</h1>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedChickenSteamRoast}
                              onChange={(event) => setCheckedChickenSteamRoast(event.target.checked)}
                            />{" "}
                            Chicken Steam Roast
                          </label>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedChickenBoti}
                              onChange={(event) => setCheckedChickenBoti(event.target.checked)}
                            />{" "}
                            Chicken Boti
                          </label>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedChickenPakora}
                              onChange={(event) => setCheckedChickenPakora(event.target.checked)}
                            />{" "}
                            Chicken Pakora
                          </label>
                        </div>

                        <div className="column">
                          <h1 className="title is-5 is-size-5-mobile tag">Soup</h1>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedChickenCornSoup}
                              onChange={(event) => setCheckedChickenCornSoup(event.target.checked)}
                            />{" "}
                            Chicken Corn Soup
                          </label>
                          <br />
                          <label class="checkbox">
                            <input
                              type="checkbox"
                              checked={checkedHotAndSourSoup}
                              onChange={(event) => setCheckedHotAndSourSoup(event.target.checked)}
                            />{" "}
                            Hot and Sour Soup
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column">
                      <h1 className="title is-5 is-size-5-mobile tag">Sweet</h1>
                      <br />
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          checked={checkedGajarHalwa}
                          onChange={(event) => setCheckedGajarHalwa(event.target.checked)}
                        />{" "}
                        Gajar Halwa
                      </label>
                      <br />{" "}
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          checked={checkedGulabJamun}
                          onChange={(event) => setCheckedGulabJamun(event.target.checked)}
                        />{" "}
                        Gulab Jamun
                      </label>
                      <br />
                    </div>
                    <div className="column">
                      <h1 className="title is-5 is-size-5-mobile tag">Tea</h1>
                      <br />
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          checked={checkedKashmiriChaiPinkTea}
                          onChange={(event) => setCheckedKashmiriChaiPinkTea(event.target.checked)}
                        />{" "}
                        Kashmiri Chai / Pink Tea
                      </label>
                      <br />{" "}
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          checked={checkedGreenTea}
                          onChange={(event) => setCheckedGreenTea(event.target.checked)}
                        />{" "}
                        Green tea
                      </label>
                      <br />
                    </div>{" "}
                    <div className="column">
                      <h1 className="title is-5 is-size-5-mobile tag">Drinks</h1>
                      <br />
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          checked={checkedColdDrinksAndWater}
                          onChange={(event) => setCheckedColdDrinksAndWater(event.target.checked)}
                        />{" "}
                        Cold Drinks and Water
                      </label>
                    </div>
                  </div>

                  <hr className="content-divider" />

                  <div className="button-background mb-5 tag title is-medium" type="submit">
                    YOUR EVENT
                  </div>

                  <div class="field-body">
                    <div className="field">
                      <label className="label title is-5">Event Date</label>
                      <div className="control">
                        <input
                          class="input"
                          type="date"
                          placeholder="Event Date"
                          value={eventDate}
                          onChange={(event) => setEventDate(event.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div class="field">
                      <label className="label title is-5">Event Time</label>
                      <div class="control is-expanded">
                        <div class="select is-fullwidth">
                          <select
                            value={selectedEventTime}
                            onChange={(event) => setSelectedEventTime(event.target.value)}
                            required
                          >
                            <option value="" disabled>
                              Select an Event Time
                            </option>
                            <option>Afternoon (1:00 pm — 4:00 pm)</option>
                            <option>Evening (7:00 pm — 10:00 pm)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="field-body mt-5">
                    <div class="field">
                      <label className="label title is-5">Number of Expected Guests</label>
                      <p class="control is-expanded has-icons-left">
                        <input
                          class="input"
                          type="text"
                          placeholder="Number of Expected Guests"
                          value={expectedGuests}
                          onChange={(event) => setExpectedGuests(event.target.value)}
                          required
                        />
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                      </p>
                    </div>

                    <div class="field">
                      <label className="label title is-5">Type of Event</label>
                      <p class="control is-expanded has-icons-left">
                        <input
                          class="input"
                          type="text"
                          placeholder="Type of Event"
                          value={typeOfEvent}
                          onChange={(event) => setTypeOfEvent(event.target.value)}
                          required
                        />
                        <span class="icon is-small is-left">
                          <i class="fas fa-user"></i>
                        </span>
                      </p>
                    </div>
                  </div>

                  <hr className="content-divider" />

                  <div className="button-background mb-5 tag title is-medium" type="submit">
                    ANYTHING ELSE
                  </div>
                  <div className="field mb-5">
                    {/* <label className="label title is-5">Anything Else We Should Know</label> */}
                    <div className="control">
                      <input
                        class="input"
                        type="textarea"
                        placeholder="I would like you to know..."
                        value={anythingElse}
                        onChange={(event) => setAnythingElse(event.target.value)}
                      />
                    </div>
                  </div>
                  <hr className="content-divider" />

                  <div class="field">
                    <div class="control">
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          checked={checkedTerms}
                          onChange={(event) => setCheckedTerms(event.target.checked)}
                          required
                        />{" "}
                        I have read and agree to the{" "}
                        <Link to="/terms" target="_blank" rel="noopener noreferrer">
                          Terms and Conditions
                        </Link>
                        .
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="has-text-left">
                <button className="button button-background mb-6" type="submit">
                  Submit Request
                </button>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
};

export default Booking;
