import { useEffect } from "react";

function Review() {
  useEffect(() => {
    window.location.replace("https://g.page/r/Cbkkea5FIpBAEBE/review");
  }, []);

  // Render a placeholder element while the navigation is being performed
  return <div>Redirecting to review page...</div>;
}

export default Review;
