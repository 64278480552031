import { Outlet } from "react-router-dom";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

import "./navigation.css";

import logo from "../../assets/musalogo.svg";

const Navigation = () => {
  const toggleBurgerMenu = () => {
    var burger = document.querySelector(".burger");
    var nav = document.querySelector("#navbarMenu");
    burger.classList.toggle("is-active");
    nav.classList.toggle("is-active");
  };
  const styles = {
    menuItemSize: {
      fontSize: "18px",
    },
    navbarBurger: {
      marginTop: "11px",
      // backgroundColor: "#382e18",
      color: "black",
      right: "12px",
      borderRadius: "10px",
      border: "1px solid black",
    },
    buttonBackground: {
      backgroundColor: "#382e18",
      color: "white",
    },
    buttonHeight: {
      marginTop: "27px",
    },
  };

  return (
    <Fragment>
      <div className="columns">
        <div className="column column-overides">
          <nav className="navbar is-fixed-top has-shadow">
            <div className="navbar-brand is-hidden-desktop">

              <ScrollLink
                to="home"
                smooth={true}
              >
                <img className="mt-3 ml-4 mb-2" src={logo} width="90" alt="Logo" />
              </ScrollLink>

              <span
                className="navbar-burger burger"
                data-target="navbarMenu"
                onClick={toggleBurgerMenu}
                style={{
                  ...styles.navbarBurger,
                }}
              >

                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>

              </span>

            </div>

            <div id="navbarMenu" className="navbar-menu mt-4 mb-1">
              <div className="navbar-start"></div>
              <div className="navbar-brand is-hidden-touch">

                <ScrollLink
                  to="home"
                  smooth={true}
                >
                  <img className="mt-2" src={logo} width="90" alt="Logo" />
                </ScrollLink>
              </div>

              <div className="navbar menu-item-size ml-5">

                <ScrollLink
                  to="home"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                >
                  HOME
                </ScrollLink>

                <ScrollLink
                  to="halls"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  OUR HALLS
                </ScrollLink>

                <ScrollLink
                  to="menus"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  OUR MENUS
                </ScrollLink>

                <ScrollLink
                  to="pakages"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  OUR PACKAGES
                </ScrollLink>

                <ScrollLink
                  to="gallery"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  GALLERY
                </ScrollLink>

                <ScrollLink
                  to="terms"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  TERMS AND CONDITIONS
                </ScrollLink>

                <ScrollLink
                  to="contact"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  CONTACT US
                </ScrollLink>

                <ScrollLink
                  to="booking"
                  smooth={true}
                  className="navbar-item has-text-weight-semibold has-text-black"
                  onClick={toggleBurgerMenu}
                  style={styles.menuItemSize}
                  offset={-120}
                >
                  <button
                    className="button button-background is-rounded menu-item-size has-text-weight-semibold"
                    style={{ ...styles.buttonBackground, ...styles.menuItemSize }}
                  >
                    REQUEST A BOOKING
                  </button>
                </ScrollLink>
              </div>
              <div className="navbar-end"></div>
            </div>
          </nav>
        </div>
      </div>
      <Outlet />
    </Fragment>

  );
};

export default Navigation;