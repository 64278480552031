import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";

const Packages = () => {

  const styles = {
    hero: {
      // backgroundColor: "#f0f0f0",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
    hr: {
      border: "1px solid #383838",
      borderRadius: "5px",
      maxWidth: "50%",
      margin: "0 auto",
    },
    subtitle: {
      fontWeight: "300",
      lineHeight: "1.6",
      color: "#383838",
      // backgroundColor: "rgba(240, 240, 240, 0.9)",
      borderRadius: "10px",
      padding: "20px",
    },
  };


  return (
    <>

      <section className="hero mb-6" style={styles.hero}>
        <div className="container has-text-centered">
          <h1
            className="title is-3 is-size-4-mobile main-title"
            style={styles.mainTitle}
          >
            Our Package
          </h1>
          {/* <hr style={styles.hr} /> */}
          <div className="columns is-centered is-vcentered mt-2">
            <div className="column is-8 pl-6 pr-6">
              <h2 className="subtitle is-5 p-4 is-size-6-mobile" style={styles.subtitle}>
                Included in your booking package, you can personalize your event with 8 vibrant chair colors at no extra charge. Additionally, our complimentary generator backup service provides protection against unexpected disruptions, ensuring a seamless experience for your guests.
              </h2>
              <h2 className="subtitle is-5 p-4 is-size-6-mobile" style={styles.subtitle}>
                For an additional cost, you can upgrade your event with our customizable temperature control package at Rs. 3000 per hour, keeping your guests comfortable and satisfied. Furthermore, choose from a variety of stage packages to make your event even more memorable. The price will vary depending on your selection, offering the perfect stage to enhance your event experience.
              </h2>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

export default Packages;
