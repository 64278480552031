import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
import "bulma-carousel/src/sass/index.sass";
import { useEffect } from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";

import Outside1 from "../../assets/Photos/Outside/Outside1.jpg";

import HallOne1 from "../../assets/Photos/HallOne/HallOne1.jpeg";
import HallOne2 from "../../assets/Photos/HallOne/HallOne2.jpeg";
import HallOne3 from "../../assets/Photos/HallOne/HallOne3.jpeg";
import HallOne4 from "../../assets/Photos/HallOne/HallOne4.jpeg";
import HallOne5 from "../../assets/Photos/HallOne/HallOne5.jpeg";
import HallOne6 from "../../assets/Photos/HallOne/HallOne6.jpeg";
import HallOne7 from "../../assets/Photos/HallOne/HallOne7.jpeg";

import HallTwo1 from "../../assets/Photos/HallTwo/HallTwo1.jpg";
import HallTwo2 from "../../assets/Photos/HallTwo/HallTwo2.jpg";
import HallTwo3 from "../../assets/Photos/HallTwo/HallTwo3.jpg";
import HallTwo4 from "../../assets/Photos/HallTwo/HallTwo4.jpg";
import HallTwo5 from "../../assets/Photos/HallTwo/HallTwo5.jpg";
import HallTwo6 from "../../assets/Photos/HallTwo/HallTwo6.jpg";
import HallTwo7 from "../../assets/Photos/HallTwo/HallTwo7.jpg";
import HallTwo8 from "../../assets/Photos/HallTwo/HallTwo8.jpg";
import HallTwo9 from "../../assets/Photos/HallTwo/HallTwo9.jpg";
import HallTwo10 from "../../assets/Photos/HallTwo/HallTwo10.jpg";
import HallTwo11 from "../../assets/Photos/HallTwo/HallTwo11.jpg";
import HallTwo12 from "../../assets/Photos/HallTwo/HallTwo12.jpg";
import HallTwo13 from "../../assets/Photos/HallTwo/HallTwo13.jpg";
import HallTwo14 from "../../assets/Photos/HallTwo/HallTwo14.jpg";
import HallTwo15 from "../../assets/Photos/HallTwo/HallTwo15.jpg";

import HallThree1 from "../../assets/Photos/HallThree/HallThree1.jpg";
import HallThree2 from "../../assets/Photos/HallThree/HallThree2.jpg";
import HallThree3 from "../../assets/Photos/HallThree/HallThree3.jpg";
import HallThree4 from "../../assets/Photos/HallThree/HallThree4.jpg";
import HallThree5 from "../../assets/Photos/HallThree/HallThree5.jpg";

import Food1 from "../../assets/Photos/Food/Food1.jpeg";
import Food2 from "../../assets/Photos/Food/Food2.jpeg";
import Food3 from "../../assets/Photos/Food/Food3.jpeg";
import Food4 from "../../assets/Photos/Food/Food4.jpeg";

const Gallery = () => {
  const styles = {
    wrapper: {
      backgroundColor: "#f0f0f0", // Change the color value as needed
      minHeight: "100vh", // To cover the whole viewport height
    },
    hero: {
      // backgroundColor: "#f0f0f0",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
  };


  useEffect(() => {
    bulmaCarousel.attach("#carousel-items", {
      slidesToScroll: 1,
      slidesToShow: 3,
      infinite: true,
    });
  }, []);
  return (
    <div style={styles.wrapper}>
      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! — How can we help?"
        avatar={logo}
      />
      <section className="hero mb-6">
        <div className="">
          <div className="container has-text-centered">

            <h1
              className="title is-3 is-size-4-mobile main-title"
              style={styles.mainTitle}
            >
              Gallery
            </h1>






            <section className="section pb-0">
              <h1 className="title is-4 is-size-5-mobile tag">Outside</h1>
              {/* <hr class="rounded" /> */}
              <div className="container is-clipped pr-3 pl-3">
                <div className="">
                  <div className="container">
                    <div id="carousel-items" className="carousel">
                      <div className="item-1 p-4">
                        <img src={Outside1} />
                      </div>

                      <div className="item-2 p-3">
                        <img src={Outside1} />
                      </div>

                      <div className="item-3 p-3">
                        <img src={Outside1} />
                      </div>

                      <div className="item-4 p-3">
                        <img src={Outside1} />
                      </div>
                      <div className="item-5 p-3">
                        <img src={Outside1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section pt-0 mt-4 pb-0">
              <h1 className="title is-4 is-size-5-mobile tag">Hall One</h1>

              <div className="container is-clipped pr-3 pl-3">
                <div className="">
                  <div className="container">
                    <div id="carousel-items" className="carousel">
                      <div className="p-4">
                        <img src={HallOne1} />
                      </div>
                      <div className="p-4">
                        <img src={HallOne2} />
                      </div>
                      <div className="p-4">
                        <img src={HallOne3} />
                      </div>
                      <div className="p-4">
                        <img src={HallOne4} />
                      </div>
                      <div className="p-4">
                        <img src={HallOne5} />
                      </div>
                      <div className="p-4">
                        <img src={HallOne6} />
                      </div>
                      <div className="p-4">
                        <img src={HallOne7} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section pt-0 mt-4 pb-0">
              <h1 className="title is-4 is-size-5-mobile tag">Hall Two</h1>
              {/* <hr class="rounded" /> */}
              <div className="container is-clipped pr-3 pl-3">
                <div className="">
                  <div className="container">
                    <div id="carousel-items" className="carousel">
                      <div className="p-4">
                        <img src={HallTwo1} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo2} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo3} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo4} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo5} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo6} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo7} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo8} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo9} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo10} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo11} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo12} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo13} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo14} />
                      </div>
                      <div className="p-4">
                        <img src={HallTwo15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section pt-0 mt-4 pb-0">
              <h1 className="title is-4 is-size-5-mobile tag">Hall Three</h1>
              {/* <hr class="rounded" /> */}
              <div className="container is-clipped pr-3 pl-3">
                <div className="">
                  <div className="container">
                    <div id="carousel-items" className="carousel">
                      <div className="p-4">
                        <img src={HallThree1} />
                      </div>
                      <div className="p-4">
                        <img src={HallThree2} />
                      </div>
                      <div className="p-4">
                        <img src={HallThree3} />
                      </div>
                      <div className="p-4">
                        <img src={HallThree4} />
                      </div>
                      <div className="p-4">
                        <img src={HallThree5} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section pt-0 mt-4 pb-0">
              <h1 className="title is-4 is-size-5-mobile tag">Food</h1>
              <div className="container is-clipped pr-3 pl-3">
                <div className="">
                  <div className="container">
                    <div id="carousel-items" className="carousel">
                      <div className="p-4">
                        <img src={Food1} />
                      </div>
                      <div className="p-4">
                        <img src={Food2} />
                      </div>
                      <div className="p-4">
                        <img src={Food3} />
                      </div>
                      <div className="p-4">
                        <img src={Food4} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="mb-6" />
      </section>
    </div>
  );
};

export default Gallery;
