import "./contact-us.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../../assets/musalogo.png";

const ContactUs = () => {

  const styles = {
    wrapper: {
      backgroundColor: "#f0f0f0", // Change the color value as needed
    },
    hero: {
      // backgroundColor: "#f0f0f0",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
  };
  return (
    <div style={styles.wrapper}>
      <FloatingWhatsApp
        phoneNumber="923205732597"
        accountName="Musa Banquet Halls"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to Musa Banquet Halls! — How can we help?"
        avatar={logo}
      />
      <section className="hero mb-6">
        <div className=""></div>
        <div className="container">
          <h1 className="title is-3 is-size-4-mobile has-text-centered main-title">Contact Us</h1>
          <div className="columns is-centered mt-6">
            <div className="column is-5 pl-5 pr-5">
              <div className="card">
                {/* <div className="card-image">
                <figure className="image">
                  <img src={AbdurRauf} alt="Placeholder image" />
                </figure>
              </div> */}
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4 has-text-centered">Abdur Rauf Khan</p>
                    </div>
                  </div>
                  <div className="subtitle is-4 has-text-centered">
                    <a href="tel:0345-5566021"> 0345-5566021 </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-5 pl-5 pr-5">
              <div className="card">
                {/* <div className="card-image">
                <figure className="image">
                  <img src={Raza} alt="Placeholder image" />
                </figure>
              </div> */}
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4 has-text-centered">M. Raza Nawaz</p>
                    </div>
                  </div>
                  <div className="subtitle is-4 has-text-centered">
                    <a href="tel:0333-5749040"> 0333-5749040 </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-5 pl-5 pr-5">
              <div className="card">
                {/* <div className="card-image">
                <figure className="image">
                  <img src={Hamza} alt="Placeholder image" />
                </figure>
              </div> */}
                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <p className="title is-4 has-text-centered">M. Hamza Nawaz</p>
                    </div>
                  </div>
                  <div className="subtitle is-4 has-text-centered">
                    <a href="tel:0320-5732597"> 0320-5732597 </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6" />
      </section>
    </div>
  );
};

export default ContactUs;
