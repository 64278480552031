import { Link } from "react-router-dom";
import { ScrollLink } from "react-scroll";

const MBH = () => {
  const styles = {
    hero: {
      backgroundColor: "#f0f0f0",
      paddingTop: "8rem",
    },
    mainTitle: {
      fontWeight: "bold",
      letterSpacing: "1px",
      color: "#383838",
    },
    hr: {
      border: "1px solid #383838",
      borderRadius: "5px",
      maxWidth: "50%",
      margin: "0 auto",
    },
    subtitle: {
      fontWeight: "300",
      lineHeight: "1.6",
      color: "#383838",
      backgroundColor: "rgba(240, 240, 240, 0.9)",
      borderRadius: "10px",
      padding: "20px",
    },
    buttonBackground: {
      backgroundColor: "#382e18",
      color: "white",
      marginTop: "0px",
      marginBottom: "30px",
    },
    videoContainer: {
      position: "relative",
      width: "100%",
      paddingBottom: "56.25%",
      overflow: "hidden",
    },
    iframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  };

  return (
    <header className="hero mb-6" style={styles.hero}>
      <div className="container has-text-centered">
        <h1
          className="title is-3 is-size-4-mobile main-title"
          style={styles.mainTitle}
        >
          Musa Banquet Halls
        </h1>

        <div className="columns is-centered is-vcentered mt-2">
          <div className="column is-8 pl-6 pr-6">
            <h2 className="subtitle is-5 p-4 is-size-6-mobile" style={styles.subtitle}>
              Musa Banquet Halls provides an affordable and exceptional venue with three distinct halls to cater to your event needs, be it weddings, office meetings, or special celebrations. Each hall offers a unique ambiance, ensuring the perfect setting for your occasion. Our extensive and diverse menu boasts a variety of delectable options, allowing you to customize your dining experience to suit the preferences of your guests. Our commitment to delivering outstanding food and service, combined with our attentive and dedicated staff, guarantees a memorable and satisfying event that will leave lasting impressions for a lifetime.            </h2>
          </div>
        </div>

        <div className="columns is-centered is-vcentered">
          <div className="column is-8">
            <div className="video-container" style={styles.videoContainer}>
              <iframe
                src="https://www.youtube.com/embed/VHCgNRb2E4Q?rel=0"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style={styles.iframe}
              ></iframe>
            </div>
          </div>
        </div>

        <Link to="/booking">
          <button
            className="button button-background is-rounded menu-item-size has-text-weight-semibold"
            style={styles.buttonBackground}
          >
            REQUEST A BOOKING
          </button>
        </Link>

      </div>
    </header >
  );
};

export default MBH;
